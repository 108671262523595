import React from 'react'
import { t } from '../../../../src/common/i18n'
import fotoRecomendaciones from '../../images/mecanico-experto-rodi.jpg'
import styles from './recomendacionCard.module.scss'
interface Props {
  recomendacion: string
}

const RecomendacionCard = ({ recomendacion }: Props) => (
  <div className={styles.ficha_recomendacion_container}>
    <img
      alt="Experto Rodi"
      className={styles.ficha_foto_lateral}
      src={fotoRecomendaciones}
    />
    <h3>{t('marquez')}</h3>
    <p>"{recomendacion}"</p>
  </div>
)

export default RecomendacionCard
