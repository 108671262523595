import { graphql, navigate } from 'gatsby'
import React, { useContext, useEffect, useState } from 'react'
import { BreadCrumbItems } from '../../components/breadCrumb/LocalizedBreadCrumb'
import Layout from '../../components/Layout'
import Title from '../../components/Title'
import { useSelectedMenu } from '../../../specific/helpers/customHooks/useSelectedMenu'
import { t, textWithLocale } from '../../i18n'
import '../../styles/singlePage.scss'
import route from '../../utils/route'
import styles from './plantillaVenta.module.scss'
import AppContext from '../../context/context'
import * as CestaActions from '../../context/actions/cestaActions'
import { Product } from '../../context/reducers/cestaReducer'
import { DataSteps, Steps } from '../../components/cesta/Steps'
import { productInfoFromId } from '../../api/cesta'
import RecomendacionCard from '../../../specific/components/RecomendacionCard/RecomendacionCard'
import CTACard from '../../components/CTACard/CTACard'
import { findMenuEntry } from '../../../specific/constants/menu'
import PriceBox from '../../components/PriceBox'
import { AIRE_ACONDICIONADO_ID } from '../../context/reducers/aireAcondicionadoReducer'

const convertToFirstUpperCase = (sentence) => {
  const lowercase = sentence ? sentence.toLowerCase() : ''
  return sentence ? sentence[0] + lowercase.slice(1) : ''
}

const PageTemplate = ({ data }) => {
  const { userInfo } = useContext(AppContext)
  useSelectedMenu(data.plantillasVentas.id_menu_principal)
  const [product, setProduct] = useState({} as Product)
  const { dispatch } = useContext(AppContext)

  useEffect(() => {
    productInfoFromId(data.plantillasVentas.id_site_producto, userInfo).then(
      (productWithInfo) => {
        setProduct(productWithInfo)
      }
    )
  }, [])

  const breadCrumItems = [
    { display: t('bread_crumb.inicio'), link: route('index') },
  ] as BreadCrumbItems

  const principal = findMenuEntry(data.plantillasVentas.id_menu_principal)
  if (principal) {
    breadCrumItems.push({
      display: t(principal.name),
      link: route(principal.route),
    })
  }

  breadCrumItems.push({
    display: convertToFirstUpperCase(
      data.plantillasVentas[textWithLocale('titulo')]
    ),
  })

  const addProductToCesta = (id_navision) => {
    if (id_navision === AIRE_ACONDICIONADO_ID) {
      return navigate(route('aire-acondicionado.asistente'))
    }

    dispatch(
      CestaActions.addProduct(
        {
          id: id_navision,
          type: 'otros',
        },
        'otros'
      )
    )
    dispatch(CestaActions.setStep(Steps.TU_SOLICITUD))
    return navigate(DataSteps[Steps.TU_SOLICITUD].route)
  }

  return (
    <Layout
      selector={false}
      breadCrumItems={breadCrumItems}
      seoData={data.seoData}>
      <div className={styles.ficha_container}>
        <Title text={data.plantillasVentas[textWithLocale('titulo')]}></Title>
        <div className={styles.ficha_container_inferior}>
          <div className={styles.bloque_izquierdo}>
            <div className={styles.ficha_container_izquierda_compra}>
              <img
                className={styles.single_promotion_image}
                src={data.plantillasVentas[textWithLocale('image_url')]}
                alt={data.plantillasVentas[textWithLocale('alt_image')]}
                width="392"
                height="143"
              />
              {product.id_navision ? (
                <PriceBox product={product} onClick={addProductToCesta} />
              ) : (
                <PriceBox
                  product={data.plantillasVentas.product}
                  onClick={addProductToCesta}
                />
              )}
            </div>
            <div className={styles.ficha_container_izquierda_disclaimer}>
              {data.plantillasVentas[textWithLocale('disclaimer')]}
            </div>
            <div className={styles.ficha_container_izquierda}>
              <div
                className={styles.ficha_texto_container}
                dangerouslySetInnerHTML={{
                  __html: data.plantillasVentas[textWithLocale('contenido')],
                }}></div>
            </div>
          </div>
          <div className={styles.ficha_container_lateral}>
            <RecomendacionCard
              recomendacion={
                data.plantillasVentas[textWithLocale('recomendacion')]
              }
            />
            {data.plantillasVentas[textWithLocale('cta_descripcion')] ? (
              <CTACard
                description={
                  data.plantillasVentas[textWithLocale('cta_descripcion')]
                }
                linkButton={data.plantillasVentas[textWithLocale('cta_link')]}
                textButton={data.plantillasVentas[
                  textWithLocale('cta')
                ].toUpperCase()}
                ctaLink={
                  data.plantillasVentas.cta === 'cita_previa'
                    ? {
                        cta: data.plantillasVentas.cta,
                        cta_es: data.plantillasVentas.cta_es,
                        cta_ca: data.plantillasVentas.cta_ca,
                        cta_pt: data.plantillasVentas.cta_pt,
                      }
                    : null
                }
              />
            ) : null}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query plantillaVentaPageQuery($id_site_plantilla_venta: Int!, $url: String!) {
    plantillasVentas(
      id_site_plantilla_venta: { eq: $id_site_plantilla_venta }
    ) {
      id_site_plantilla_venta
      id_site_producto
      id_menu_principal
      ruta_es
      ruta_ca
      titulo_es
      titulo_ca
      contenido_ca
      contenido_es
      menu_principal
      image_url_ca
      image_url_es
      alt_image_ca
      alt_image_es
      disclaimer_es
      disclaimer_ca
      cta_descripcion_es
      cta_descripcion_es
      recomendacion_ca
      recomendacion_es
      cta_es
      cta_ca
      cta_link_es
      cta_link_ca
      product {
        precio
        importe
        importeSinPromo
        promocion {
          id_site_promocion_cesta
          cantidad_promocionada
          cupon_descuento
          descripcion_ca
          descripcion_es
          id_site_promocion_cesta
          importePromocion
          importe_cupon
          restriccion_pago_total
          restriccion_pago_parcial
          tipo
        }
      }
    }
    seoData: pageInfo(url: { eq: $url }) {
      ...seoinformation
    }
  }
`
