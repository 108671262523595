import React from 'react'
import Button from '../buttons/Button'
import styles from './priceBox.module.scss'
import { Product } from '../../context/reducers/cestaReducer'
import { isNullDiscount } from '../../context/selectors/cestaSelectors'
import { intl, t } from '../../i18n'

interface Props {
  product: Product
  onClick: (id_navision: string) => void
}

const PriceBox = ({ product, onClick }: Props) => {
  if (
    (product.promocion && !isNullDiscount(product.promocion)) ||
    product.importe !== product.importeSinPromo
  ) {
    return (
      <div className={styles.price_and_button_box}>
        <div className={styles.price_box}>
          <div>
            <div className={styles.oferta_label}>oferta online</div>
            <div className={styles.crossed_price}>
              <del>
                {product.importeSinPromo
                  ? intl.formatNumber(Number(product.importeSinPromo), {
                      style: 'currency',
                      currency: 'EUR',
                    })
                  : null}
              </del>
            </div>
            <div className={styles.price_when_promo}>
              {product.importe
                ? intl.formatNumber(
                    product.importe - product.promocion.importePromocion,
                    {
                      style: 'currency',
                      currency: 'EUR',
                    }
                  )
                : null}
            </div>
          </div>
        </div>
        <div className={styles.button_box}>
          <Button onClick={() => onClick(product.id_navision)}>
            {t('cesta.general.añadir_producto')}
          </Button>
        </div>
      </div>
    )
  }
  return (
    <div
      className={`${styles.price_and_button_box}  ${styles.no_promo_padding}`}>
      <div className={styles.price_box}>
        <div className={styles.price}>
          {intl.formatNumber(product.importe, {
            style: 'currency',
            currency: 'EUR',
          })}
        </div>
      </div>
      <div className={styles.button_box}>
        <Button onClick={() => onClick(product.id_navision)}>
          {t('cesta.general.añadir_producto')}
        </Button>
      </div>
    </div>
  )
}

export default PriceBox
